import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {SwiperContainer} from "swiper/swiper-element";
import {ModalService} from "../modal.service";
import {PluginsService} from "../../../core/plugins.service";
import {ApiService} from "../../../core/api.service";
import {ActivatedRoute, Router} from "@angular/router";
import {UserService} from "../../../core/user.service";
import {UtilService} from "../../../core/util.service";
import {Location} from "@angular/common";
import {ToursService} from "../../../core/tours.service";
import {ModalController} from "@ionic/angular";
import {EventActionEnum} from "../../enums/event-action.enum";
import {AnalyticsService} from "../../../core/analytics.service";

export interface FeatureFlowModalData {
  featureId: string;
}

@Component({
  selector: 'app-feature-flow',
  templateUrl: './feature-flow.component.html',
  styleUrls: ['./feature-flow.component.scss'],
})
export class FeatureFlowComponent implements OnInit {

  /**
   * Feature Flows
   */

  public selectedFeatureFlow: any = null;
  public activeStep: any = null;
  public featureFlows = [
    {
      id: 'personal-shopping',
      title: 'Personal Shopping',
      steps: [
        {
          id: 'info',
          type: 'info',
          templateId: 'personalShoppingInfo',
          start: true,
          nextStepId: 'service-usages',
          actionButton: true,
        },
        {
          id: 'service-usages',
          type: 'poll',
          title: 'How would you like to use this service?',
          subtitle: 'Tell us more about how our personal shopping service can help you',
          prevStepId: 'info',
          actionButton: false,
          options: [
            {
              title: 'I would like to become a personal shopper for existing partners',
              nextStepId: null
            },
            {
              title: 'I would like to integrate a personal shopping service and team into my brand',
              nextStepId: null
            },
            {
              title: "I'm already a personal shopper and would like to improve my operations",
              nextStepId: null
            },
            {
              "title": "None of the above. I want to find more information about how this works.",
              nextStepId: null
            }
          ]
        }
      ]
    },
    {
      id: 'shopify',
      title: 'Shopify',
      steps: [
        {
          id: 'info',
          type: 'info',
          templateId: 'shopifyIntegrationInfo',
          start: true,
          nextStepId: 'user-type',
          actionButton: true,
        },
        {
          id: 'user-type',
          "type": "poll",
          "title": "Tell us about your Shopify store",
          "subtitle": "We’d like to understand how we can support your Shopify store through Fliproom.",
          "prevStepId": "info",
          "actionButton": false,
          "options": [
            {
              "title": "I don't have one but I am thinking about starting a Shopify store and need help building it.",
              nextStepId: null

            },
            {
              "title": "I already have a Shopify store and want to connect it to Fliproom.",
              nextStepId: 'amount'

            },
            {
              "title": "I have my Shopify connected and am looking to improve and scale my business with your services.",
              nextStepId: null
            },
            {
              "title": "None of the above. I want to find more information about how this works.",
              nextStepId: null
            }
          ]
        },
        {
          id: 'amount',
          "type": "poll",
          "title": "How many shopify orders do you process monthly?",
          "subtitle": "We’d like to understand how we can support your Shopify store through Fliproom.",
          "prevStepId": "user-type",
          "actionButton": false,
          "options": [
            {
              "title": "Less than 200 sales per month",
              nextStepId: 'extra-info'
            },
            {
              "title": "200-500 sales per month",
              nextStepId: 'extra-info'
            },
            {
              "title": "More than 500 sales per month",
              nextStepId: 'extra-info'
            }
          ]
        },
        {
          id: 'extra-info',
          "type": "poll",
          "title": "What would you like to achieve by connecting your Shopify store with Fliproom?",
          "subtitle": "We’d like to understand how we can support your Shopify store through Fliproom.",
          "prevStepId": "amount",
          "actionButton": false,
          "options": [
            {
              "title": "I want to increase sales",
              nextStepId: null
            },
            {
              "title": "I want a better way to manage my sale channels",
              nextStepId: null
            },
            {
              "title": "I want to build a marketplace on my Shopify store",
              nextStepId: null
            },
            {
              "title": "Something else",
              nextStepId: null
            }
          ]
        }
      ]
    },
    {
      id: 'virtual-stock',
      title: 'Virtual Stock',
      steps: [
        {
          id: 'info',
          type: 'info',
          templateId: 'virtualInventoryInfo',
          start: true,
          nextStepId: 'usage-type',
          actionButton: true,
        },
        {
          id: 'usage-type',
          "type": "poll",
          "title": "How would you like to utilize our Virtual Inventory?",
          "subtitle": "Select the option that best describes your current needs.",
          "prevStepId": "info",
          "end": true,
          "actionButton": false,
          "options": [
            {
              "title": "I am already using a dropshipping model and want to find systematic ways to scale it.",
              nextStepId: 'amount'
            },
            {
              "title": "I am interested in integrating a virtual inventory system with my store to increase potential sales and product availability.",
              nextStepId: 'amount'
            },
            {
              "title": "I would like to sync my inventory with the sneaker market API offered by Fliproom.",
              nextStepId: 'amount'
            },
            {
              "title": "None of the above. I want to find more information about how this works.",
              nextStepId: null
            }
          ]
        },
        {
          id: 'amount',
          "type": "poll",
          "title": "How many products would you like to sell through our Virtual Inventory?",
          "subtitle": "Select the option that best describes your current needs.",
          "prevStepId": "usage-type",
          "actionButton": false,
          "options": [
            {
              "title": "Less than 50 products",
              nextStepId: null
            },
            {
              "title": "50 to 150 products",
              nextStepId: null
            },
            {
              "title": "More than 150 products",
              nextStepId: null
            }
          ]
        }
      ]
    },
    {
      id: 'consignment',
      title: 'Consignment Integration',
      steps: [
        {
          id: 'info',
          type: 'info',
          templateId: 'consignmentInfo',
          start: true,
          nextStepId: 'usage-type',
          actionButton: true,
        },
        {
          id: 'usage-type',
          "type": "poll",
          "title": "What drives your interest in our consignment system?",
          "subtitle": "Please tell us more about your current needs and what you are looking for in a consignment solution.",
          "prevStepId": "info",
          "end": true,
          "actionButton": false,
          "options": [
            {
              "title": "I have a good consignor network and would like an improved way to work with them through Fliproom.",
              nextStepId: 'consignors-amount'
            },
            {
              "title": "I'm already using a consignment model but would like to find a better way to manage it.",
              nextStepId: 'consignors-amount'
            },
            {
              "title": "I am looking for new ways to expand my inventory with a low-risk option.",
              nextStepId: null
            },
            {
              "title": "None of the above. I want to find more information about how this works.",
              nextStepId: null
            }
          ]
        },
        {
          id: 'consignors-amount',
          "type": "poll",
          "title": "How many consignors are you planning to work with?",
          "subtitle": "Please tell us more about your current needs and what you are looking for in a consignment solution.",
          "prevStepId": "usage-type",
          "actionButton": false,
          "options": [
            {
              "title": "Less than 10",
              nextStepId: null
            },
            {
              "title": "10 to 50",
              nextStepId: null
            },
            {
              "title": "More than 50",
              nextStepId: null
            }
          ]
        }
      ]
    },
    {
      id: 'integrations-supplier',
      title: 'Become a store supplier',
      steps: [
        {
          id: 'info',
          type: 'info',
          templateId: 'becomeASupplierInfo',
          start: true,
          nextStepId: 'usage-type',
          actionButton: true,
        },
        {
          id: 'usage-type',
          "type": "poll",
          "title": "Explore Your Supplier Opportunities",
          "subtitle": "We'd like to know more about your interests in connecting with other Shopify stores.",
          "prevStepId": "info",
          "end": true,
          "actionButton": false,
          "options": [
            {
              "title": "I already know other Shopify stores I would like to connect my stock to.",
              nextStepId: 'amount'
            },
            {
              "title": "I am looking for new ways to grow my sales and increase brand exposure.",
              nextStepId: null
            },
            {
              "title": "I want to know more about your partnered stores and inquire to work with them.",
              nextStepId: null
            },
            {
              "title": "None of the above. I want to find more information about how this works.",
              nextStepId: null
            }
          ]
        },
        {
          id: 'amount',
          "type": "poll",
          "title": "How many partner Shopify stores are you looking to connect with?",
          "subtitle": "Select the option that best describes your current needs.",
          "prevStepId": "usage-type",
          "actionButton": false,
          "options": [
            {
              "title": "Less than 10 stores",
              nextStepId: null
            },
            {
              "title": "10 to 50 stores",
              nextStepId: null
            },
            {
              "title":"More than 50 stores",
              nextStepId: null
            }
          ]
        }
      ]
    },
    {
      "id": "integrations-seller",
      "title": "Connect Suppliers",
      "steps": [
        {
          "id": "info",
          "type": "info",
          "templateId": "connectSupplierInfo",
          "start": true,
          "nextStepId": "supplier-type",
          "actionButton": true
        },
        {
          "id": "supplier-type",
          "type": "poll",
          "title": "How do you want to connect with suppliers through Fliproom?",
          "subtitle": "Select the option that best describes your interest in connecting with suppliers via Fliproom.",
          "prevStepId": "info",
          "end": true,
          "actionButton": false,
          "options": [
            {
              "title": "I want to integrate my existing suppliers into Fliproom.",
              nextStepId: 'amount'
            },
            {
              "title": "I am interested in connecting with new suppliers through Fliproom.",
              nextStepId: null
            },
            {
              "title": "None of the above. I want to find more information about how this works.",
              nextStepId: null
            }
          ]
        },
        {
          id: 'amount',
          "type": "poll",
          "title": "How many partner Shopify suppliers are you looking to connect with?",
          "subtitle": "Select the option that best describes your current needs.",
          "prevStepId": "supplier-type",
          "actionButton": false,
          "options": [
            {
              "title": "Less than 10 stores",
              nextStepId: null
            },
            {
              "title": "10 to 50 stores",
              nextStepId: null
            },
            {
              "title":"More than 50 stores",
              nextStepId: null
            }
          ]
        }
      ]
    }
  ]


  constructor(
    private _modalService: ModalService,
    private _plugins: PluginsService,
    private _api: ApiService,
    public user: UserService,
    private _modalController: ModalController,
    private _analytics: AnalyticsService
  ) { }

  @ViewChild('swiper') swiper!: ElementRef<SwiperContainer>;
  @Input() data: FeatureFlowModalData;

  public pollResponses= []
  public dynamicStepStack = []


  ngOnInit() {
    this.selectedFeatureFlow = this.featureFlows.find(flow => flow.id === this.data.featureId)
    this.activeStep = this.selectedFeatureFlow.steps.find(step => step.start === true)
    this.selectedFeatureFlow.steps.map(step => {
      this.dynamicStepStack.push(step)
    })
  }

  ngAfterViewInit() {
    this.resetFlow()
    this._analytics.trackEvent('feature-interest-view', {name: this.data.featureId})
    this.createEvent(`feature-interest-${this.data.featureId}`, EventActionEnum.Viewed)
    this.swiper.nativeElement.swiper.allowSlideNext = false
    this.swiper.nativeElement.swiper.allowSlidePrev = false
  }

  resetFlow(){
    this.swiper.nativeElement.swiper.activeIndex = 0
    this.selectedFeatureFlow = null
    this.pollResponses = []
    this.selectedFeatureFlow = this.featureFlows.find(flow => flow.id === this.data.featureId)
    this.activeStep = this.selectedFeatureFlow.steps.find(step => step.start === true)
    this.selectedFeatureFlow.steps.map(step => {
      this.dynamicStepStack.push(step)
    })
  }

  createEvent(resource: string, action: EventActionEnum, metadata?: any) {
    const body = { resource, action, metadata };
    this._api.createEvent(body).subscribe((resp) => {});
  }

  onBack() {
    if(!this.activeStep.start){
      this.activeStep.type === 'poll' ? this.pollResponses.pop() : null
      const prevStep = this.selectedFeatureFlow.steps.find(step => step.id === this.activeStep.prevStepId)
      const prevStepIdx = this.dynamicStepStack.findIndex((step) => step.id === prevStep.id)
      this.swiper.nativeElement.swiper.allowSlidePrev = true
      this.swiper.nativeElement.swiper.slideTo(prevStepIdx)
      this.swiper.nativeElement.swiper.allowSlideNext = false
      this.activeStep = prevStep
    } else {
      this._modalController.dismiss()
    }
  }

  onNext(selectedOption = null) {
    if(selectedOption && selectedOption.nextStepId){
      const nextStep = this.selectedFeatureFlow.steps.find((step) => step.id === selectedOption.nextStepId)
      const nextStepIdx = this.dynamicStepStack.findIndex((step) => step.id === nextStep.id)
      this.swiper.nativeElement.swiper.allowSlideNext = true
      this.swiper.nativeElement.swiper.slideTo(nextStepIdx)
      this.swiper.nativeElement.swiper.allowSlideNext = false
      this.activeStep = nextStep
    }
      else if(!!this.activeStep.nextStepId){
        const nextStep = this.selectedFeatureFlow.steps.find((step) => step.id === this.activeStep.nextStepId)
        const nextStepIdx = this.dynamicStepStack.findIndex((step) => step.id === nextStep.id)
        this.swiper.nativeElement.swiper.allowSlideNext = true
        this.swiper.nativeElement.swiper.slideTo(nextStepIdx)
        this.swiper.nativeElement.swiper.allowSlideNext = false
        this.activeStep = nextStep
      }
      else {
        this.onFeatureFlowCompleted()
      }


  }

  onFeatureFlowCompleted(){
    this.createEvent(`feature-interest-${this.data.featureId}`, EventActionEnum.Interested, {pollResponses: this.pollResponses})
    this._analytics.trackEvent('feature-interest-confirmed', {name: this.data.featureId})
    this._modalService.success('Interest Request Sent successfully!. We will get back to you soon via email.')
    this._modalController.dismiss({}, 'submit')
  }

  get activeIndex(){
    return this.swiper.nativeElement.swiper.activeIndex
  }

  onPollResponseSelected(response: any) {
    this.pollResponses.push({question: this.activeStep.title, response: response.title})
    this.onNext(response)
  }

}
