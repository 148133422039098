import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { SwiperContainer } from 'swiper/element';
import { ModalService } from '../modal.service';
import { ModalController } from '@ionic/angular';
import { AddressContactPage } from '../../components/address-contact/address-contact.page';
import { UserService } from 'src/app/core/user.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Address } from '../../models/Address.model';
import { forkJoin } from 'rxjs';
import { ApiService } from 'src/app/core/api.service';
import { mergeMap } from 'rxjs/operators';

export interface IFlowWizard {
  steps: IFlowWizardStep[];
}

export interface IFlowWizardStep {
  id: string
  title: string;
  subtitle: string;
  actions: any;
}

@Component({
  selector: 'app-flow-wizard',
  templateUrl: './flow-wizard.component.html',
  styleUrls: ['./flow-wizard.component.scss'],
})
export class FlowWizardComponent implements OnInit {
  @ViewChild('swiper') swiper!: ElementRef<SwiperContainer>;
  @Input() data: IFlowWizard;

  public steps = [
    {
      id: 'account_type',
      title: 'Choose Your Journey',
      message: 'Help us customize the app to best fit your needs.',
      options: [
        {id: 'retailer', text: "I'm a Retailer"},
        {id: 'consignor', text: "I'm a Consignor"},
      ]
    },
    {
      id: 'stock_levels',
      title: 'Units in stock',
      message: '',
      options: [
        {id: '0-20', text: "0 - 20"},
        {id: '20-50', text: "20 - 50"},
        {id: '50-200', text: "50 - 200"},
        {id: '200+', text: "200+"},
      ]
    },
    {
      id: 'sales_volume',
      title: 'Sales last month?',
      message: '',
      options: [
        {id: '0-10', text: "0 - 10"},
        {id: '10-100', text: "10 - 100"},
        {id: '100-200', text: "100 - 200"},
        {id: '200+', text: "200+"},
      ]
    },
    {
      id: 'product_type',
      title: 'What are you selling?',
      message: '',
      options: [
        {id: 'footwear', text: "Footwear"},
        {id: 'apparel', text: "Apparel"},
        {id: 'accessories', text: "Accessories"},
        {id: 'homegoods', text: "Home Goods"},
        {id: 'electronics', text: "Electronics"},
        {id: 'beauty', text: "Beauty"},
        {id: 'other', text: "Other"},
      ]
    },
    {
      id: 'intent',
      title: 'Why are you here?',
      message: "",
      cssClass: 'intent-custom-styling',
      multipleOptions: true,
      options: [
        {id: 'i-want-to-get-started-with-consignment', text: 'I want to get started with consignment'},
        {id: 'i-want-to-increase-my-sales', text: 'I want to increase my sales'},
        {id: 'i-want-to-build-a-marketplace', text: 'I want to build a marketplace'},
        {id: 'i-need-to-streamline-my-operations', text: 'I need to streamline my operations'},
        {id: 'i-need-to-better-manage-my-sale-channels', text: 'I need to better manage my sale channels'},
        {id: 'im-looking-for-new-ways-to-expand-my-inventory', text: 'I\'m looking for new ways to expand my inventory'}
      ],
      buttons: [
        {id: "next", text: "Next"},
      ]
    },
    {
      id: 'accountSetup',
      title: 'Finish Setup',
      customContent: true,
      lastStep: true,
      message: "",
      buttons: [
        {id: "submit", text: "Submit"},
      ]
    },
  ]
  public accountSetupForm = new FormGroup({
    accountName: new FormControl(null, Validators.required),
    accountCurrency: new FormControl(null, Validators.required),
    shippingAddress: new FormControl(null, Validators.required),
  })

  private setupData = {
    account_type: null,
    stock_levels: null,
    sales_volume: null,
    product_type: null,
    intent: []
  }

  public isLoading = false

  constructor(
    private _modalService: ModalService,
    private _modalCtrl: ModalController,
    private _user: UserService,
    private _api: ApiService
  ) {
    this.accountSetupForm.patchValue({
      accountName: this._user.account.name,
      accountCurrency: this._user.account.currency,
      shippingAddress: this._user.account.warehouses[0].address
    })
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.swiper.nativeElement.swiper.allowSlideNext = false
    this.swiper.nativeElement.swiper.allowSlidePrev = false
    this.swiper.nativeElement.swiper.allowTouchMove = false
  }

  onButtonClick(buttonId: string) {
    console.log('onButtonClick', buttonId);
    const currentStep = this.currentStep
    switch (currentStep.id) {
      case 'account_type':
        this.setupData['account_type'] = buttonId
        break;
      case 'stock_levels':
        this.setupData['stock_levels'] = buttonId
        break;
      case 'sales_volume':
        this.setupData['sales_volume'] = buttonId
        break;
      case 'product_type':
        this.setupData['product_type'] = buttonId
        break;
      case 'intent':
        if (buttonId == 'next') break;
        //add or remove the intent option
        const index = this.setupData['intent'].indexOf(buttonId)
        if (index > -1) {
          this.setupData['intent'].splice(index, 1)
        } else {
          this.setupData['intent'].push(buttonId)
        }
        break;
      default:
        break;
    }

    //dont't go to next slide if multiple options clicked and not next button
    if (currentStep.multipleOptions && buttonId != 'next') {
      return
    }

    if (currentStep.multipleOptions && buttonId == 'next' && this.setupData['intent'].length == 0) {
      this._modalService.warning('Please select at least one option')
      return
    }

    if (buttonId == 'submit') {
      this.accountSetupForm.markAllAsTouched();
      if (!this.accountSetupForm.valid) {
        this._modalService.warning('Please fill in all required fields')
        return
      }

      if (!this.shippingAddress.validated) {
        this._modalService.warning('Please validate the stock location ')
        return
      }

      this.isLoading = true

      forkJoin({
        updateAccount: this._api.updateAccount(this._user.account.ID, {
          onboardingCompleted: true,
          name: this.accountSetupForm.get('accountName').value,
          currency: this.accountSetupForm.get('accountCurrency').value,
          onboardingQuestions: JSON.stringify(this.setupData),
        }),
        onboardingSurvey: this._api.createEvent({
          resource: 'onboarding-survey',
          action: 'submitted',
          metadata: this.setupData
        })
      }).pipe(
        mergeMap(() => this._api.getAccountInfo())
      )
      .subscribe((res) => {
        this._modalCtrl.dismiss(null, 'submit')
      })
      return
    }

    this.swiper.nativeElement.swiper.allowSlideNext = true
    this.swiper.nativeElement.swiper.slideNext()
    this.swiper.nativeElement.swiper.allowSlideNext = false
  }

  get currentStep(): any {
    return this.steps[this.swiper?.nativeElement?.swiper?.activeIndex || 0]
  }

  get shippingAddress(): Address {
    return new Address(this.accountSetupForm.get('shippingAddress').value)
  }

  isOptionSelected(optionId): boolean {
    if (Array.isArray(this.setupData[this.currentStep.id])) {
      return !!this.setupData[this.currentStep.id].find(option => option == optionId)
    } else {
      return this.setupData[this.currentStep.id] == optionId
    }
  }

  onEditShipping(): void {
    this._modalService.open(AddressContactPage, {address: this.shippingAddress, formOnly: true, maskFields: ['email']}).subscribe((updatedAddress: Address) => {
      this.accountSetupForm.patchValue({
        shippingAddress: updatedAddress
      })
    })
  }

}
